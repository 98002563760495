<template>
  <b-card title="Certificaciones">
    <b-row>
      <b-col
        md="10"
        class="my-1"
      >
        <b-button variant="primary" v-b-modal.modal-certificacion @click="accion='registrar'" class="mr-1">
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Nuevo</span>
        </b-button>

        <b-button @click="actualizarLista()" variant="secondary" class="btn-icon mr-1" >
          <feather-icon
            icon="RotateCcwIcon"
          />
        </b-button>
      </b-col>
      
      <b-col md="2" class="mt-1">        
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay
          :show="loading"
        >
          <b-table class="sm" responsive="sm" 
              ref="selectableTable"
              selectable
              select-mode="single" 
              hover
              striped
              :small="true"
              :per-page="perPage"
              :current-page="currentPage"
              :items="items" 
              :fields="fields"
              @row-selected="onRowSelected"
              show-empty
              empty-text="No se encontraron registros coincidentes"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              >
              <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <i class="feather icon-disc primary" />
                  </template>

                  <template v-else>
                    <i class="feather icon-circle" />
                  </template>
              </template>
      
              <template #cell(afiliado)="data">
                {{ data.item.afiliado.apellidos }} {{data.item.afiliado.nombres}}
                <br/>
                <strong>R.N.: {{ data.item.afiliado.rn }}</strong>
              </template>

              <!-- <template #cell(estado)="data">
                <b-badge :variant="estado[1][data.value]">
                  {{ estado[0][data.value] }}
                </b-badge>
              </template> -->

              <template #cell(pago)="data">
                <b-badge 
                  v-if="data.item.pago"
                  :variant="estado[1][data.item.pago.estado]">
                  {{ estado[0][data.item.pago.estado] }}
                </b-badge>
                <b-badge 
                  v-else
                  variant="light-warning">
                    Pago no registrado
                </b-badge>
              </template>

              <template #cell(accion)="data">
                <div style="min-width: 170px;">
                  <b-button
                    v-if="data.item.pago == null"
                    v-b-modal.modal-pago @click="certificacion=data.item"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
                    v-b-tooltip.hover.bottom="'Registrar Pago'"
                    class="btn-icon mr-1"
                    size="sm"
                  >
                    <feather-icon icon="DollarSignIcon" />
                  </b-button>
                  <template v-else>
                    <b-button
                      v-b-modal.modal-certificado @click="verCertificado(data.item, true)"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-info"
                      v-b-tooltip.hover.bottom="'Imprimir Certificado'"
                      class="btn-icon mr-1"
                      size="sm"
                    >
                      <feather-icon icon="PrinterIcon" />
                    </b-button>
                    <b-button
                      v-b-modal.modal-certificado @click="verCertificado(data.item, false)"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-info"
                      v-b-tooltip.hover.bottom="'Ver Certificado'"
                      class="btn-icon mr-1"
                      size="sm"
                    >
                      <feather-icon icon="FileTextIcon" />
                    </b-button>
                  </template>
                  
                  <b-button
                    v-b-modal.modal-certificacion @click="abriComponente(data.item)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    v-b-tooltip.hover.bottom="'Editar'"
                    class="btn-icon mr-1"
                    size="sm"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    @click="confirmar_eliminar(data.item.id)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    v-b-tooltip.hover.bottom="'Eliminar'"
                    class="btn-icon"
                    size="sm"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <CertificacionNuevoEditar 
      :accion="accion" 
      :item="item"
      @cerrarComponente="cerrarComponente" 
      @actualizarLista="actualizarLista"
    />
    <VisorCertificado 
      :certificado="certificado"
      :imprimir="imprimir"
      @cerrarComponente="certificado = null"
    />
    <PagoNuevo 
      :certificacion="certificacion"
      @actualizarLista="actualizarLista"
      @cerrarComponente="certificacion = null"
    />
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BCard, BCardText, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BDropdown, BDropdownItem, BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import CertificacionNuevoEditar from './CertificacionNuevoEditar.vue';
import VisorCertificado from './VisorCertificado.vue';
import PagoNuevo from './PagoNuevo.vue';

export default {
    components: {
        BCard,
        BCardText,
        BTable,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BDropdown,
        BDropdownItem,
        CertificacionNuevoEditar,
        BOverlay,
        vSelect,
        VisorCertificado,
        PagoNuevo
    },
    directives: {
      Ripple,
      'b-tooltip': VBTooltip,
    },
    data(){
      return{
        imprimir: null,
        certificado: null,
        certificacion: null,
        items:[],
        fields: [
            { key: 'afiliado', label: 'Nombres y Apellidos', sortable: false },
            { key: 'proyecto', label: 'Proyecto', sortable: false },
            { key: 'cuce', label: 'CUCE', sortable: false },
            { key: 'nombre_entidad', label: 'Nombre Entidad', sortable: false },
            { key: 'cargo', label: 'Cargo', sortable: false },
            // { key: 'estado', label: 'Estado', sortable: false },
            { key: 'pago', label: 'Estado Pago', sortable: false },
            { key: 'created_at', label: 'Registrado', sortable: false },
            { key: 'updated_at', label: 'Actualizado', sortable: false },
            'accion',
        ],
        selected: [],
        filter: null,
        filterOn: [],
        perPage: 10,
        pageOptions: [10, 25, 50],
        totalRows: 1,
        currentPage: 1,
        accion:'',
        loading:false,
        obligatorio: [{
          1: 'Si', 2: 'No'
        }],
        estado: [{
          1: 'Verificado', 0: 'Sin Verificar',
        },
        {
          1: 'light-success', 0: 'light-danger',
        }],
        item:[],
        tipoCobro: '',
        opcionesTipoCobro:[
          {title: 'Todos', value: ''},
          {title: 'Mensual', value: 'MENSUAL'},
          {title: 'Unico', value: 'UNICO'},
        ],
      }
    },
    created(){        
        this.loading=true       
        this.listar();
    }, 
    watch: {
      tipoCobro: function () {
        this.loading = true;
        this.listar();
      },
    }, 
    methods: {
        onRowSelected(items) {
          this.selected = items
        },
        selectAllRows() {
          this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
          this.$refs.selectableTable.clearSelected()
        },
        selectThirdRow() {
          this.$refs.selectableTable.selectRow(2)
        },
        unselectThirdRow() {
          this.$refs.selectableTable.unselectRow(2)
        },
        onFiltered(filteredItems) {
          this.totalRows = filteredItems.length
          this.currentPage = 1
        },
        listar(){
            var url = '/admin/certificacion';
            if(this.filter)
              url += '?rn='+this.filter;
             
            axiosIns.get(url)
            .then(res => {
                this.items=res.data.data;
                this.currentPage=res.data.current_page;
                this.totalRows = res.data.total;
                this.loading=false
            })
            .catch(err =>{
                console.log(err);
            });
        },
        actualizarLista(){
          this.loading=true;
          this.listar();
          this.accion='';
          this.item=[];
        },
        abriComponente(item){
          this.accion="actualizar";
          this.item=item;
        },
        verCertificado(item, imprimir){
          this.certificado=item;
          this.imprimir = imprimir;
        },
        cerrarComponente(){
          this.accion='';
          this.item=[];
        },
        confirmar_eliminar(id) {
            // axiosIns.get('/admin/certificacion/'+id)
            // .then(res => {
            //   let respuesta=res.data;
            //   if(respuesta){
            //       this.$swal({
            //         icon: "warning",
            //         title: "Advertencia!",
            //         timer: 2000,
            //         text: "No se puede eliminar.",
            //         confirmButtonText: "Aceptar",
            //         customClass: {
            //           confirmButton: "btn btn-success",
            //         },
            //       });
            //   }else{
                
            //   }
            // })
            // .catch(err =>{
            //     console.log(err);
            // });
            this.$swal({
              title: "Esta seguro de eliminar ?",
              text: "¡No podrás revertir esto!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Eliminar",
              cancelButtonText: "Cancelar",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.eliminar(id);
              }
            });
        },
        eliminar(id) {
         axiosIns.delete('/admin/certificacion/'+id)
          .then(res => {
              this.$bvToast.toast(res.data.mensaje, {
                title: 'Mensaje',
                variant:'success',
                solid: false,
              })
              
              this.actualizarLista();
          }) 
          .catch(err =>{
              console.log(err);
          });
        },
    },
}
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>