var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"my-modal",attrs:{"id":"modal-certificacion","title":_vm.titulo,"no-close-on-backdrop":"","ok-title":"Guardar","cancel-variant":"outline-secondary","cancel-title":"Cerrar"},on:{"cancel":_vm.cerrarModal,"close":_vm.cerrarModal,"ok":function($event){$event.preventDefault();return _vm.validar.apply(null, arguments)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"afiliado"}},[_c('label',[_vm._v("Afiliado "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"afiliado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Buscar afiliado","label":"nombre_completo","options":_vm.afiliadosSearch,"state":errors.length > 0 ? false:null},on:{"search":_vm.buscarAfiliado},model:{value:(_vm.afiliado),callback:function ($$v) {_vm.afiliado=$$v},expression:"afiliado"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.afiliado),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"proyecto"}},[_c('label',[_vm._v("Proyecto "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"proyecto","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"proyecto","state":errors.length > 0 ? false:null,"placeholder":"Nombre del proyecto"},model:{value:(_vm.proyecto),callback:function ($$v) {_vm.proyecto=$$v},expression:"proyecto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.proyecto),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"cuce"}},[_c('label',[_vm._v("CUCE")]),_c('validation-provider',{attrs:{"name":"cuce","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cuce","state":errors.length > 0 ? false:null,"placeholder":"CUCE"},model:{value:(_vm.cuce),callback:function ($$v) {_vm.cuce=$$v},expression:"cuce"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.cuce),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"nombre_entidad"}},[_c('label',[_vm._v("Nombre Entidad "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"nombre entidad","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nombre_entidad","state":errors.length > 0 ? false:null,"placeholder":"Nombre de la entidad"},model:{value:(_vm.nombre_entidad),callback:function ($$v) {_vm.nombre_entidad=$$v},expression:"nombre_entidad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.nombre_entidad),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"cargo"}},[_c('label',[_vm._v("Cargo")]),_c('b-form-input',{attrs:{"id":"cargo","placeholder":"Nombre del cargo"},model:{value:(_vm.cargo),callback:function ($$v) {_vm.cargo=$$v},expression:"cargo"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"razon_social"}},[_c('label',[_vm._v("Razón Social "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"razon social","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"razon_social","state":errors.length > 0 ? false:null,"placeholder":"Razón social"},model:{value:(_vm.razon_social),callback:function ($$v) {_vm.razon_social=$$v},expression:"razon_social"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.razon_social),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }